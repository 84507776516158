<template>
  <div id="app">
    <div class="tab-buttons" >
      <button @click="changeTab(1)" class="buttons" :class="{ register_register: activeTab === 1, register_software: activeTab === 2 }" id="tab-left">
        <p>Registro</p>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAABUUlEQVRIS+3W4RHBMBQH8GYDI9QGTOBswAY6AUYwARvoCDbABt2ADdig/n+X9pJI0qTVng/enfMl8et7yXslkgFDDGglrbCyLFd8SCFEHvOw0RigDYC9RA4At6FgFGZAlZGFZhiMydIxo5ElkyAwCAM0AXB2QJU9R4YXX0kbsUCIxhMfgoUL9GKAUpkRv0PCCzoxQDwblo4ljIk7Fk+RIWEtrJiEjli5iFGUtQWwaSP2BagyLgDnKviRGTBmtGqZkblNAzXM0bRd3XrK1JhsWmbVRyxR0pOKcTpw7vUR7+zMMvL28crzUw3btvgOG9kGbAGe3dN19VMsuLVV5D72mjZNfhpjSczJwCrYonNmJ5Rmqf6ypy//2PvVYruNvZSRffawnHqOM8uMM3MNgzHWss/q8L3POE1mBrgze0e+YNdYp97KK9YdzIdt/FvQsbG17S/3/J0c5VErdQAAAABJRU5ErkJggg=="/>
      </button>
      <router-link to="/software" class="buttons">
        <button  @click.native="changeTab(2)" class="buttons" :class="{ software_register: activeTab === 1, software_software: activeTab === 2 }" id="tab-right">
          <p>Software</p>
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAABE0lEQVRIS2NkoCNgpKNdDERZ9v///wCgo+yBWACP4w4yMjIuwOd4gpYBLSoAGtBPZAgUAi2cgEstMZatB2oG+ewCED/AYZABUFwBJA+0TJESy84DNYMMawQa1IDNIKDvQeL1g9MyoOtAkQ9yXQKBhEBk9MGVTQCGSCGIB48zEhMCqRaCEw6yZfOhviLVIGLUbwBaFjgyLAMl33hiwoQMNQeAwZhIMFOTYTBOLaTGGTiikU0DpmJiEhZZCYSuloHKxgNo4eQA5CsQCG6yfEZuFI5aRm7Ioegb2GAEVf2gJgAtwAKUEgSYOUHJ9z4NbPoANDMQaNkBlOIKaCGo+ge1N6gFQBaBykVQ+4W4phy1bKZrQQwA8taIHAJKyScAAAAASUVORK5CYII="/>
        </button>
      </router-link>
    </div>
    <div class="tab-content">
        <Register v-if="activeTab === 1"/>
        <router-view v-if="activeTab === 2" />
    </div>
  </div>
</template>
  

<script setup>
  import { provide } from 'vue';
  import { useChangeTab } from '../src/useChangeTab.js';
  import Register from './pages/Register.vue';

  const { activeTab, changeTab } = useChangeTab();

  provide('changeTab', changeTab);
  provide('activeTab', activeTab);
</script>

<style lang="scss" scoped>

  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
  
  *{
    font-family: 'Lato', sans-serif;
  }
  .tab-buttons{
    position: absolute;
    width: 100%;
    z-index: 8000;
  }
  .buttons{
    width: 50%;
    height: 8vh;
    font-size: 2rem;
    cursor: pointer;
    border: none;
  }

  //  Buttons en vista REGISTRO
  .register_register{
    background-color: transparent;
    color: #fff;
  }

  .register_register p,  .software_register p{
    margin: 0;
  }

  .register_register img, .software_register img{
    display: none;
  }

  .software_register{
    background-color: #03223f;
    color: #fff;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
  }

  // Buttons en vista SOFTWARE
  .register_software{
    background-color: #03223f;
    color: #fff;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  }

  .register_software p, .software_software p{
    margin: 0;
  }

  .register_software img,.software_software img{
    display: none;
  }

  .software_software{
    background-color: transparent;
    color: #fff;
  }

  // New Buttons Software Only 
  .new_buttons, .buttons_new, .new_register_register, .new_software_register{
    display: none;
  }
  
  @media (max-width: 1280px){
    .buttons{
      font-size: 1.2rem;
    }
  }

  @media (max-width: 800px){
    .tab-buttons{
      display: flex;
      flex-direction: row;
      width: 40%;
      position: fixed;
      bottom: 0;
      right: 0;
      padding: 0;
    }
    .buttons{
      height: 74.5px;
      padding: 0;
      background-color: #243757;
      width: 100%;
      font-size: 20px !important;
      clip-path: none;
      box-shadow: 1px 1px 4px #000;
    }

    //  Buttons en vista REGISTRO
    .register_register{
      background-color: rgb(194, 155, 104);
    }

    .software_register{
      border-left: 1px solid #fff;
    }

    .register_register p,  .software_register p{
      display: none;
    }

    .register_register img, .software_register img{
      display: inline-block;
      width: 35px;
      height: 35px;
      padding-top: 7px;
    }

    // Buttons en vista SOFTWARE
    .software_software{
      border-left: 1px solid #fff;
      background-color: rgb(194, 155, 104);
    }

    .register_software p,  .software_software p{
      display: none;
    }

    .register_software img, .software_software img{
      display: inline-block;
      width: 35px;
      height: 35px;
      padding-top: 7px;
    }

    // New Buttons Software Only 
    .new_buttons{
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 64%;
      width: 100%;
      z-index: 6000;
    }

    .buttons_new{
      width: 50%;
      height: 6vh;
      font-size: 2rem;
      cursor: pointer;
      border: none;
      clip-path: none;
    }

    .new_register_register{
      display: block;
      background-color: rgb(194, 155, 104);
    }

    .new_software_register{
      display: block;
      background-color: #243757;
    }

    .new_register_register p,  .new_software_register p{
      font-size: 0.6em;
      color: #fff;
      text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.5);
      margin: 0;
    }
  }

  @media (max-width: 500px){
    .new_buttons{
      top: 69%;
    }
  }
 
</style>