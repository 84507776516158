<template>
  <section class="contact_container">
    <div class="container">
      <h2 class="contact_content title">Contáctate con Nosotros</h2>
      <p class="contact_content subtitle">Completa nuestro formulario de contacto <br>y déjanos saber cómo podemos ayudarte.</p>
      <FormContact/>
    </div>
  </section>
</template>


<script setup>
import FormContact from '../components/FormContact.vue';
import { titleChange } from '../titleChange';

titleChange.setTitle('/contacto', 'Contacto');

</script>


<style lang="scss" scoped>
.contact_container{
  background-color: #243757;
  background-image: url(../assets/dogprint.svg);
  background-repeat: repeat;
  padding: 90px;
  padding-bottom: 40px;
  display: grid;
  padding-top: 180px;
  place-items: center;
}

.container{
  background-color: hwb(0 100% 0%);
  border-radius: 8px;
  width: 80%;
  padding: 10px 150px;
  margin-top: 20px;
  padding-bottom: 50px;
  animation: zoomIn 2s ease;
}

@keyframes zoomIn {
    from {
        transform: scale(0.5); 
        opacity: 0;
    }
    to {
        transform: scale(1); 
        opacity: 1;
    }
}
.title{
    text-align: center;
    color: #243757;
    font-size: 45px;
    padding-bottom: 0;
    margin-bottom: 15px;
  }

  .subtitle{
    text-align: center;
      color: grey;
      font-size: 22px;
      margin-bottom: 60px;
  }

  @media (max-width: 1280px){
    .contact_container{
      padding-top: 90px; 
    }
    .container{
      padding: 10px 100px 30px 100px; 
    }
    .title{
      font-size: 35px;
    }
    .subtitle{
      font-size: 20px;
      margin-bottom: 40px;
      text-wrap: balance;
    }
  }
  @media (max-width: 800px){
    .contact_container{
      padding: 10px;
      padding-bottom: 20px;
    }

    .container{
    padding: 10px 35px;
    overflow: hidden;
   }

   .title{
      font-size: 25px;
      text-wrap: balance;
    }

    .subtitle{
      font-size: 18px;
      margin-bottom: 25px;
      text-wrap: balance;
    }
  }
</style>