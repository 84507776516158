<template> 
     <section class="first_container">
            <div class="left_grid">
                <h2 class="title">Kennel Club Digital</h2>
                <h3 class="subtitle"> Software para Registro Genealógico</h3>
                <p class="paragraph">Diseñado exclusivamente para el registro de información de animales.</p>

                <a href="#form-container" class="btn scroll">Contáctanos</a>
            </div>
     </section> 
    
    <section class="second_container">
        <div class="second_content">
            <div class="content_item">
                <img src="../assets/icon-sw-1.svg" >
                <p><strong>Registro Genético Integral</strong> <br><br>
                    El Kennel Club Digital ofrece un software especializado en genealogía animal. Desde perros hasta aves, registra y organiza ancestros con sesiones personalizadas y niveles de usuario. ¡Todo en PDF!
                </p>
            </div>
            
            <div class="content_item">
                <img src="../assets/icon-sw-2.svg">
                <p><strong>Adaptabilidad Total </strong> 
                    <br><br> Sea cual sea la especie, este software se adapta a tus necesidades. Con funciones flexibles y sesiones específicas para cada usuario, es tu aliado para registros genealógicos.
                </p>
            </div>

            <div class="content_item">
                <img src="../assets/icon-sw-3.svg" >
                <p><strong>Seguridad y Conexión Continua</strong> <br><br>
                    Trabaja con tranquilidad en línea, respaldado por copias de seguridad diarias. El Kennel Club Digital protege tus datos mientras te mantiene conectado con tu genealogía animal.
                </p>
            </div>
        </div>
    </section>

    <section class="third_container">
            <div class="third_content_item">
                <img src="../assets/illust-sw-1.svg" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                <div class="illust_content">
                    <h3>Software <mark style="background-color: #243757; color: #fff; display: inline-block; padding: 5px;">Integral</mark>  de Registro Genealógico</h3>
                    <p>Toda la información necesaria de tus registros se concentrarán en tan solo un sistema. Podrás registrar y transferir de manera segura, teniendo la base de datos al alcance de un click. Este software no solo simplifica la gestión de datos, sino que también garantiza la seguridad y privacidad de la información almacenada.</p>

                    <a href="#form-container" class="btn-item scroll">Consultar</a>
                </div>
            </div>

            <div class="third_content_item">
                <div class="illust_content">
                    <h3>Gestión Personalizada con el Kennel Club Digital</h3>
                    <p>El Kennel Club Digital es un software de registro genealógico adaptable a cualquier especie. Cada usuario tiene su propia sección de auto gestión con niveles personalizados. Registra y organiza datos en PDF, trabaja en línea y realiza copias de seguridad diarias para una experiencia confiable y segura.</p>

                    <a href="#form-container" class="btn-item scroll">Consultar</a>
                </div>
                <img src="../assets/illust-sw-2.svg" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
            </div>

            <div class="third_content_item">
                <img src="../assets/illust-sw-3.svg" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                <div class="illust_content">
                    <h3>El mundo <mark style="background-color: #243757; color: #fff; display: inline-block; padding: 5px;">digital</mark> a tu alcance con nuestro software  </h3>
                    <p>Presentamos una innovadora solución digital que ofrece máxima comodidad a tus socios. Con este software, la autogestión genealógica se vuelve accesible desde cualquier lugar del mundo, permitiendo a los usuarios gestionar y actualizar sus registros en línea con facilidad. Únete a la evolución digital y disfruta de la administración online. </p>

                    <a href="#form-container" class="btn-item scroll">Consultar</a>
                </div>
            </div>
    </section>   

    <section class="fifth_content">
            <img class="img_content" src="../assets/software-computer.png">
            <div class="fifth_content_div">
                <div class="fifth_check">
                    <h3>Obtenga ventajas sobre su competencia con el Software  del Kennel Club Digital.</h3>
                    <div class="content_content" data-aos="fade-right" data-aos-easing="ease-in-sine">
                        <img src="../assets/check.svg">
                        <p>Acceso personalizado con diferentes niveles.</p>
                    </div>
                    <div class="content_content" data-aos="fade-left" data-aos-easing="ease-in-sine">
                        <img src="../assets/check.svg">
                        <p>Generación automática de archivos en PDF.</p>
                    </div>
                    <div class="content_content"  data-aos="fade-right" data-aos-easing="ease-in-sine">
                        <img src="../assets/check.svg">
                        <p>Adaptabilidad a diversas especies y necesidades.</p>
                    </div>
                    <div class="content_content" data-aos="fade-left" data-aos-easing="ease-in-sine">
                        <img src="../assets/check.svg">
                        <p>Medidas de seguridad.</p>
                    </div>
                </div>

                <div class="fifth_boxes">
                    <div data-aos="flip-up">
                        <img src="../assets/hostinger.svg" class="flip1">
                        <p class="flip1-p">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatem labore velit magnam ad rem optio assumenda doloremque odit perferendis, eius harum pariatur quas quaerat, deleniti commodi? Sed obcaecati quasi consequuntur.</p>
                    </div>
                    <div data-aos="flip-up">
                        <img src="../assets/amazon.png" class="flip2">
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatem labore velit magnam ad rem optio assumenda doloremque odit perferendis, eius harum pariatur quas quaerat, deleniti commodi? Sed obcaecati quasi consequuntur.</p>
                    </div>
                </div>
            </div> 
    </section>
  
    <section class="form_container" id="form-container">
        <FormModal/>
    </section>

    <button v-if="showBackToTopButton" @click="scrollToTop" class="back-to-top-btn"><img src="../assets/go-up.svg" ></button>

    <section class="sixth_container">
            <h4>PAISES QUE YA CUENTAN CON NUESTRO SISTEMA</h4>
            <div class="banderas">
                <img src="../assets/bandera-arg.png" style="width: 50px; height: 50px;">
                <img src="../assets/bandera-es.png" style="width: 45px; height: 45px;">
            </div>       
    </section>

    <footer style="background-color: #fff; height: 60px; padding-top: 40px;">
        <div class="footer_social">
            <a href="https://www.facebook.com/KennelClubDigital/" target="_blank"><img src="../assets/facebook-footer.svg"></a>
            <a href="https://www.instagram.com/kennelclubdigital/" target="_blank"><img src="../assets/instagram-footer.svg"></a>
            <a href="https://api.whatsapp.com/send?phone=5491128382378&text=%F0%9F%98%8A%20Hola,%20vengo%20de%20la%20página%20web.%20Quiero%20más%20información%20por%20favor." target="_blank"><img src="../assets/whatsapp-footer.svg"></a>
        </div>
        <h3 class="footer_copyright">Copyright &copy; 2024 Kennel Club Digital</h3>
    </footer>
    
     
  
</template>

<script setup>
    import FormModal from '@/components/FormModal.vue'
    import AOS from 'aos';
    import 'aos/dist/aos.css';
    import { onMounted, onUnmounted,ref } from 'vue';
    import { titleChange } from '../titleChange';

    AOS.init();

    const showBackToTopButton = ref(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        showBackToTopButton.value = window.scrollY > window.innerHeight / 2; 
    };


    onMounted(() => {
        window.addEventListener('scroll', handleScroll);

        const links = document.querySelectorAll('.scroll');
        
        links.forEach(link => {
            link.addEventListener('click', function(e) {
                e.preventDefault();
                
                const targetId = this.getAttribute('href').substring(1);
                const targetElement = document.getElementById(targetId);
                
                if (targetElement) {
                    targetElement.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            });
        });
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
    });

    titleChange.setTitle('/software', 'Software')
</script>

<style scoped lang="scss">

*{
    margin: 0;
    padding: 0;  
    scroll-behavior: smooth;
}

.back-to-top-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 5px 10px;
  background-color: rgb(0, 0, 0, 0.1);
  color: rgb(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
}

@media (min-width: 1400px){
    .content_content{
        margin-left: 18% !important;
    }
}

// First Section
.first_container {
    background-image: url(../assets/software-header.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding: 0 5%;
    padding-top: 11%;
    // background: rgb(255,255,255);
}

.left_grid{
    display: flex;
    flex-direction: column;
    align-self: center;
    order: 2;
    max-width: 50%;
}
.title{
    color: #fff;
    font-size: 60px;
    font-family: 'Elen Sans';
}
.subtitle{
    color: #e19f41;
    font-family: 'Elen Sans';
    font-weight: 500;
    font-size: 55px;
    text-wrap: balance;
    margin-top: 10px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

.paragraph{
  color: #fff;
  font-size: 20px;
  margin-top: 15px;
  z-index: 1;
  line-height: 1.5;
  text-wrap: balance;
}

.btn{
    font-family: 'Elen Sans';
    text-align: center;
    font-weight: lighter;
    z-index: 1;
    cursor: pointer;
    margin: 8% 33%;
    padding: 3% 5%;
    font-size: 140%;
    text-decoration: none;
    color:#fff;
    border: 1px solid rgb(255, 255, 255, 0.5);
    border-radius: 50px;
    transition: background-color 0.2s ease-in-out, font-weight 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    background-color: transparent; 
    box-shadow: none; 
}

.btn:hover{
    background-color: #e19f41;
    font-weight: bold;
    color: #fff;
    border-color: #e19f41;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.324);
}

.img{
  z-index: 1;
  width: 600px;
  margin: 0;
  filter: drop-shadow(6px 18px 18px rgba(0, 0, 0, 0.6));
}

// Second Section
.second_container{
    background-color: #ffff;
    position: relative;
    height: auto;
    padding: 5% 0;
    padding-bottom: 0;
    margin-bottom: 0;
}
.second_content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px;
    justify-items: center;
    padding: 5% 8%;
    padding-bottom: 4%;
    margin-bottom: 0;
}
.content_item{
    display: grid;
    justify-items: center;
}

.content_item img{
    width: 100px;
}

.content_item p{
    font-size: 20px;
    height: 200px;
    padding: 8% 0;
    text-align: center;
    transition: font-size 0.3s ease-out, padding 0.3s;
}

.content_item p:hover{
    font-size: 21px;
}

// Third Section
.third_container{
    background-color: #ffff;
    position: relative;
    padding-bottom: 5%;
}

.third_content_item{
    display: grid;
    height: 70vh;
    gap: 50px;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    padding: 0 13%;
}

.illust_content{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.third_content_item img{
    width: 500px;
    height: 500px;
    margin: 0 auto;
    filter: drop-shadow(1px 1px 1px rgb(0, 0, 0, 0.4));
}

.third_content_item h3{
    font-size: 30px;
    font-weight: 600;
}

.third_content_item p{
    font-size: 20px;
    line-height: 1.5;
}

.btn-item{
    font-family: 'Elen Sans';
    text-align: center;
    font-weight: bold;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    color:#fff;
    border: 1px solid rgb(255, 255, 255, 0.5);
    border-radius: 50px;
    background-color: #e19f41; 
    box-shadow: none; 
    padding: 3% 5%;
    margin: 4% auto 0 auto;
}

// Fifth Section
.fifth_content{
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(225, 159, 65, 0.5) 25%, #a6c1ee68 62%,  rgba(255,255,255,1) 90%); 
    display: grid;
    height: 65vh;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    padding: 5% 0;
    overflow: hidden;
}

.img_content{ 
    width: 723px;
    height: 415px;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
}

.fifth_content_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
    justify-self: center;
}

.fifth_check{
    text-wrap: balance;
}

.fifth_check h3{
    font-size: 28px;
    text-align: center;
}

.content_content{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: start;
    align-items: start;
    font-size: 21px;
    margin-top: 5%;
    margin-left: 8%;
}

.fifth_boxes{
    display: none;
}

.fifth_boxes div{
    display: none;
    // background-color: rgb(225, 159, 65, 0.5);
    // border: 1px solid #000;
    // // box-shadow: 1px 1px 4px #000;
    // height: 200px;
    // width: 80%;
    // padding: 20px;
}

// Form 
.form_container{
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 20px;
}

// Sixth Section
.sixth_container{
    background-color: #ffff;
    height: 15vh;
    border-top: 1px solid #243757;
    border-bottom: 1px solid #243757;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


.banderas{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
    width: 100%;
}

// Footer
.footer_social{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.footer_copyright{
    background-color: #ffff;
    display: flex;
    height: 6vh;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}


@media (max-width: 1280px){

    // First Section
    .back-to-top-btn{
        padding: 3px;
        border-radius: 20% !important;
    }

    .back-to-top-btn img{
        width: 30px;
    }

    .title{
        font-size: 45px;
    }

    .subtitle{
        font-size: 35px;
    }

    .paragraph{
        font-size: 19px;
        line-height: 25px;
        margin-top: 10px;
    }

    .btn{
        font-size: 120%;
        margin-top: 7%;
    }

    // Second Section
    .second_container{
        margin: 0;
        padding-bottom: 0 !important;
    }

    .second_content{
        padding-bottom: 0 !important;
    }

    .content_item img{
        width: 80px;
        height: 80px;
    }

    .content_item p{
        font-size: 16px;
        // padding: 20px 50px;
    }

    .content_item p:hover{
        font-size: 18px;
    }

    // Third Section
    div.third_content_item{
        height: 70vh;
        padding: 40px 100px;
        gap: 50px;
    }

    .third_content_item h3{
        font-size: 25px;
    }

    .third_content_item p{
        font-size: 18px;
    }
    
    .third_content_item img{
        width: 400px;
        height: 300px;
    }

    // Fifth Section

    .fifth_content_div{
        height: 85vh;
    }

    .img_content{
        width: 115%;
        height: 100%;
        margin: 0 !important;
    }

    .fifth_check{
        margin-bottom: 0;
    }

    .fifth_check h3{
        font-size: 22px;
    }

    .content_content{
        font-size: 18px;
        margin-top: 20px;
        margin-left: 4%;
    }

    .fifth_boxes{
        display: none;
    }

    .fifth_boxes div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 170px;
        padding: 0;
        width: 100%;
    }

    // Form

    .form_container{
        padding: 30px 0 20px 0;
    }


    // Sixth Section
    .sixth_container{
        height: 15vh;
        gap: unset;
    }

    .banderas{
        gap: 50px !important;
    }

    .sixth_container h4{
        margin-bottom: 10px;
        font-size: 14px;
    }

    .banderas img{
        height: 35px !important;
        width: 35px !important;
    }

    // Footer
    .footer_social a img{
        height: 25px;
    }
}

@media (max-width: 1199px) {
    //    First
   .img{
        width: 600px;
        height: 410px;
    }

    // Second
   .second_container{
        padding-bottom: 60px;
    }

    // Third

    .third_content_item{
        padding-bottom: 0;
        padding-top: 0;
    }

    // Fifth

    .fifth_content{
        height: 85vh;
    }

    .fifth_content_div{
        height: 85vh;
    }
    .fifth_check{
        padding: 0 50px 0 50px;
    }

    .fifth_boxes{
        margin-bottom: 0;
        overflow: visible;
    }    

    .img_content{
        margin-left: 60px;
    }

}

@media (max-width: 1080px){
    // First

    .title{
        font-size: 35px;
    }

    .subtitle{
        font-size: 30px;
    }

    .paragraph{
        font-size: 19px;
    }

    .btn{
        font-size: 15px;
    }

    .img{
        width: 550px;
        height: 370px;
    }

    .left_grid{
        margin-top: 46px;
    }

    // Second
    .second_content{
        padding-bottom: 0;
    }

    .second_container{
        padding-top: 80px;
    }

    // Third
    .third_content_item{
        padding: 0 70px;
        height: 70vh;
    }

    .third_content_item h3{
        font-size: 25px;
    }

    .third_content_item p{
        font-size: 18px;
    }

    // Fifth

    .fifth_boxes p{
        font-size: 12px;
        // margin: 28px 30px 10px 30px;
    }

    .flip1, .flip2{
        margin: 0;
    }
}

@media (max-width: 1000px){
    .left_grid{
        margin-top: 50px;
    }

    .fifth_boxes{
        display: none;
    }
}

@media (max-width: 939px){
    .title{
        font-size: 30px;
    }

    .subtitle{
        font-size: 25px;
    }

    .paragraph{
        font-size: 17px;
    }

    // 

    .content_item p{
        font-size: 14px;
    }

    // 

    .third_content_item{
        height: 60vh;
    }

    .third_content_item img{
        width: 350px;
    }

    .third_content_item h3{
        font-size: 20px;
    }

    .third_content_item p{
        font-size: 16px;
    }

    // 
    .fifth_content{
        height: 70vh;
    }

    .fifth_content_div{
        height: 70vh;
    }

    .img_content{
        width: 300px;
        height: 400px;
    }

    .fifth_boxes{
        display: none;
    }

    .fifth_check{
        margin: 0;
    }

    .fifth_check h3{
        margin-bottom: 50px;
    }
}

@media (max-width: 883px){
    .first_container{
        height: 470px;
        padding-top: 50px;
    }

    .left_grid{
        margin-top: 60px;
    }

    .paragraph{
        font-size: 15px;
    }

    .img{
        margin-top: 80px;
        width: 500px;
        height: 350px;
    }
    // 

    .content_item p{
        font-size: 13px;
    }
    .content_item p:hover{
        font-size: 15px;
    }

    // 

    .second_container{
        padding-top: 60px;
        padding-bottom: 20px;
    }

    // 

    .third_content_item{
        padding: 0 50px;
    }
    .third_content_item img{
        width: 300px;
    }
}

@media (max-width: 800px){
    .first_container{
        padding-top: 80px;
        height: 300px;
    }
    .left_grid{
        margin: 0;
        // margin-top: 30px;
        margin-left: 50px;
    }

    .img{
        width: 400px;
        height: 310px;
        margin: 0;
    }

    .wave{
        top: 230px;
    }

    .btn{
        margin-top: 10px;
    }

    // 
    .second_content{
        padding: 30px 60px;
        gap: 30px;
    }
    .second_container{
        padding: 0;
        padding-top: 70px;
    }

    .content_item img{
        width: 60px;
        height: 60px;
    }

    .content_item p{
        padding: 20px 10px;
    }

    .content_item p:hover{
        font-size: 16px;
    }

    // 

    .third_container{
        height: 190vh;
    }

    .third_content_item{
        height: 60vh;
        padding: 0 40px;
    }

    .third_content_item img{
        width: 300px;
    }

    .third_content_item h3{
        font-size: 19px;
    }

    // 
    .fifth_content{
        height: 70vh;
    }

    .fifth_check{
        padding: 0 40px;
    }

    .fifth_check h3{
        font-size: 18px;
        margin-bottom: 20px;
    }

    .fifth_check p{
        font-size: 14px;
    }
    
    // 
    .sixth_container{
        height: 14vh;
    }
}

// @media (max-width: 768px){
//     .first_container{
//         padding-top: 100px;
//         height: 60vh;
//     }

//     .wave{
//         top: 17rem;
//     }

//     .second_container{
//         padding-top: 30px;
//     }

//     .img_content{
//         margin-left: 30px;
//     }
// }

// @media (max-width: 722px){
//     .wave{
//         top: 300px;
//     }
// }

// @media (max-width: 698px){
//     .wave{
//         top: 306px;
//     }
//     .img_content{
//         width: 250px;
//     }

//     .paragraph{
//         line-height: 1.2;
//     }
// }

// @media (max-width: 680px){
//     .first_container{
//         padding-top: 80px;
//     }

//     .title, .subtitle, .paragraph, .btn, .img{
//         position: absolute;
//     }

//     .img{
//         top: 140px;
//         left: 5px;
//     }

//     .btn{
//         right: 100px;
//         bottom: 190px;
//     }

//     .paragraph{
//         top: 180px;
//         padding: 0 50px;
//         left: 350px;
//     }

//     .title, .subtitle{
//         text-align: center;
//     }

//     .title{
//         top: 75px;
//         bottom: 0;
//         left: 0;
//         right: 0;
//     }

//     .subtitle{
//         top: 115px;
//         bottom: 0;
//         left: 0;
//         right: 0;
//     }

//     .wave{
//         top: 260px;
//     }

//     // 
//     .second_container{
//         padding-top: 60px;
//     }
    
//     // 
//     .third_container{
//         height: auto;
//         padding: 30px 0;
//     }
//     .third_content_item{
//         height: auto;
//         grid-template-columns: none;
//         grid-template-rows: 1fr 1fr;
//         padding: 50px 0;
//     }

//     .third_content_item img{
//         height: 200px;
//         grid-row: 1;
//     }

//     .third_content_item h3{
//         text-align: center;
//         line-height: 25px;
//         padding: 30px 90px 0 90px;
//     }

//     .third_content_item p{
//         text-align: center;
//         line-height: 25px;
//         padding: 0 40px;
//     }
// }

// @media (max-width: 600px){
//     .paragraph{
//         padding: 0;
//         left: 340px;
//     }

//     .img{
//         left: -18px;
//     }

//     .btn{
//         bottom: 220px;
//         right: 80px;
//     }

//     .wave{
//         top: 250px;
//     }

//     //
    
//     .second_content{
//         padding: 30px 40px 0 40px;
//     }
    
// }

@media (max-width: 500px){
    .first_container{
        height: unset;
        padding-top: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left_grid{
        margin: 0;
        padding: 0;
        align-items: center;
        max-width: none;
    }

   .first_content{
        padding: 0;
        display: flex;
        flex-direction: column;
    }

   .title, .subtitle, .paragraph{
        text-align: center;
        text-wrap: balance;
    }

   .title{
    font-size: 28px;
   }

   .subtitle{
    font-size: 23px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
   }

   .img{
     display: none;
   }


   .btn{
    margin-top: 30px;
   }

    //
    
    .second_container{
        padding-top: 60px;
    }

    .second_content{
        grid-template-columns: none;
        grid-template-rows: repeat(3, 1fr);
        padding: 0;
        gap: unset;
    }

    .content_item{
        padding: 4% 15% 10% 15%;
    }

    .content_item p{
        height: unset;
        padding: 10px;
        font-size: 14px;
        text-wrap: balance;
    }

    .content_item p:hover{
        font-size: 14px;
    }

    .content_item img{
        width: 50px;
        height: 50px;
    }

    // 
    .third_container{
        height: unset;
        padding-top: 10%;
        padding-bottom: 0;
    }

    .third_content_item, .illust_content{
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        order: 2;
        height: unset;
    }

    .third_content_item{
        padding: 0 30px !important;
        padding-bottom: 10% !important;
        gap: 20px !important;
    }

    .third_content_item img, .third_content_item h3, .third_content_item p{
        margin: 0;
    }

    .third_content_item img{
        width: 250px;
        height: 250px;
    }

    .third_content_item h3{
        font-size: 16px;
        text-wrap: balance;
        margin-bottom: 10px;
    }
    
    .third_content_item p{
        font-size: 14px;
        text-wrap: balance;
    }

    // 
    .img_content{
        display: none;
    }

    .fifth_content{
        // display: unset;
        grid-template-columns: none;
        height: 45vh;
        padding: 0
    }

    .fifth_content_div{
        padding: 0 20px;
        height: 45vh;   
    }

    .fifth_check{
        padding: 0;
        width: 100%;
    }

    .fifth_check h3{
        text-wrap: balance;
        margin-bottom: 30px;
    }

    .content_content{
        gap: 5px;
        padding: 0 20px;
        margin-bottom: 30px;
    }

    // Form
    .form_container{
        padding: 0;
        padding-top: 20px;
    }

    div.form_container{
        width: 70%;
    }

}
</style>