<template>
    <section class="prices">
        <div class="prices_container">
            <h2 class="prices_content title">Lista de Precios del Kennel Club Digital</h2>
            <h4 class="prices_content subtitle">Listado de aranceles</h4>

            <p class="paragraph">
            <b>Inscripción de Criadero:</b> US$ 20
            <br><br>
            <b>Transferencia de Titularidad de Ejemplares argentinos:</b> US$ 35
            <br><br>
            <b>Registro de Ejemplares sin Pedigree:</b> US$ 20
            <br><br>
            <b>Ingreso de Ejemplares ya registrados:</b> US$ 15
            <br><br>
            <b>Inscripción de Camada:</b> US$ 10 <i>por cada cachorro.</i>
            <br><br>

            <span class="span1"><span style="color: red; font-weight: bold; margin-bottom: 10px; font-size: 25px">ATENCIÓN</span> 
            Para el pago de los aranceles se enviará un enlace de PayPal. 
            <span>Es necesario notificar el pago a este <a href="https://api.whatsapp.com/send?phone=5491128382378&text=%F0%9F%98%8A%20Hola,%20vengo%20a%20notificar%20mi%20pago.%20" target="_blank">WhatsApp</a>.</span>
            Los trámites se envían en formato PDF.</span>
            </p>

            <!-- <table class="prices_table">
                <thead>
                    <th>Cantidad de Cachorros</th>
                    <th>No Socios</th>
                    <th>Socios</th>
                    <th>Socios Honorarios</th>
                </thead>
                
                <tbody>
                    <tr>
                        <td>1 cachorro</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                    <tr>
                        <td>2 cachorros</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                    <tr>
                        <td>3 cachorros</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                    <tr>
                        <td>4 cachorros</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                    <tr>
                        <td>5 cachorros</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                    <tr>
                        <td>6 cachorros</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                    <tr>
                        <td>7 cachorros</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                    <tr>
                        <td>8 cachorros</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                    <tr>
                        <td>9 cachorros</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                    <tr>
                        <td>10 cachorros</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                        <td>$1234,00</td>
                    </tr>
                </tbody>
            </table> -->
        </div>
    </section>
</template>


<script setup>
import { titleChange } from '../titleChange';

titleChange.setTitle('/lista-de-precios', 'Lista de Precios');

</script>


<style lang="scss" scoped>
.span1{
    line-height: 1.5;
    font-size: 23px;
    width: 60%;
    color: rgb(36, 55, 87,0.8) ;
    margin: 40px auto;
    border: 2px solid rgb(0, 0, 0, 0.3);
    padding: 15px;
    display: grid;
    align-content: center;
    text-align: center;
}

.prices{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
}

@keyframes slideInFromTop {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.prices_container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
    animation: slideInFromTop 2s ease;
}

.title{
    text-align: center;
    color: #243757;
    font-size: 45px;
    padding-bottom: 0;
    margin-bottom: 15px;
}
.subtitle{
    text-align: center;
    color: grey;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 60px;
}
.paragraph{
    text-align: justify;
    color: rgb(86, 86, 86);
    font-size: 22px;
    text-align: left;
    line-height: 1.5;
    word-spacing: 7px;
}

// .prices_table{
//     width: 80%; 
//     height: 70vh;
//     margin: auto;
// }
// .prices_table th {
//     background-color: #E19F41;
//     border: 1px solid #ddd;
//     color: #fff;
//     padding: 8px;
//     text-align: center;
//     font-size: 1.2rem;
// }
// .prices_table td{
//     border: 1px solid #ddd;
//     padding: 8px;
//     text-align: center;
//     font-size: 1.2rem;
// }

@media (max-width: 1400px){
    .prices{
        padding-top: 160px; 
    }
}

@media (max-width: 1280px){
    .span1{
        width: 80%;
        font-size: 20px;
    }

    .prices{
        padding-top: 90px; 
    }
    .prices_container{
        padding: 10px 100px 30px 100px;
    }
    .title{
        font-size: 35px;
    }
    .subtitle{
        font-size: 25px;
        margin-bottom: 40px;
    }
    .paragraph{
        font-size: 20px;
        word-spacing: 5px;
        text-align: justify;
    }
}

@media (max-width: 800px){
    .prices{
        padding: 10px;
        padding-bottom: 20px;
    }

    .prices_container{
        padding: 10px 35px;
        padding-bottom: 20px;
    }

    .title{
        font-size: 25px;
        text-wrap: balance;
    }

    .subtitle{
        font-size: 20px;
        margin-bottom: 30px;
    }

    .paragraph{
        font-size: 15px;
    }

    .span1 span{
        font-size: 14px !important;
    }

    .span1{
        font-size: 14px;
        padding: 10px;
        margin: 20px auto;
        width: 100%;
        text-wrap: balance;
    }

}
</style>