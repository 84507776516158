<template>
    <div id="app">
        <div class="container-header">
            <div class="container-menu">
                <button class="icon"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAA3CAYAAACo29JGAAABIUlEQVRoQ+2YUQ0CQQxEbx0gASfggOAAKUjAARaQgARwgJOjm8AvbbOTSdnMfTftdN5uu7m2TPy1iXtb1Ny/0hU5kSvogI5lQSghSSIXsqlgkMgVhBKSJHLrul7NqlPILl7QrbV2/FUuRE7N8Yh9K4mc67kdy37fDm4gN+Bpd+48fOe4mnHVQgMFV46bSc1x/cZVEzmcl9xMIXK2CvYma8OV5lZ72Sp4DK8CPb9co+EBen65ls5+LPtA2boucAP6QLkPDxSuZly10CrAleNmUnNcv3HVRA7nJTdTiNznN8OOK82t1n8zXIZXwexLXD9l3YOEDdDDGetnoWyhaVlIb0qKmkvZVShY5ArBSEkRuZRdhYJFrhCMlBSRS9lVKHhqcm8r9VA4OqK/oQAAAABJRU5ErkJggg=="/></button>
                <nav class="menu">
                    <ul>
                        <li><router-link to="/">Inicio</router-link></li>
                        <li>Quienes Somos <span class="menu-icon"><img src="../assets/menu-icon.svg"></span>
                            <ul>
                                <li><router-link to="/historia-de-los-registros-genealogicos">Historia de los Registros Genealógicos</router-link></li>
                                <li><router-link to="/usos-y-costumbres-en-materia-de-crianza">Usos y Costumbre en Materia de Crianza</router-link> </li>
                                <li><router-link to="/legalidad-juridica" >Legalidad Jurídica en la Propiedad de Caninos de Raza</router-link><span class="submenu-icon"><box-icon name='chevron-right' style="width: 15px; height: 15px;"></box-icon></span> 
                                    <ul>
                                        <li><router-link to="/certificado-por-KCD">¿Qué significa que tu perro sea certificado por el KCD?</router-link></li>
                                    </ul>
                                </li>
                                <li><router-link to="/asesoramiento-frente-a-reclamos">Asesoramiento Legal Frente a Reclamos</router-link></li>
                                <li><span class="submenu-icon"><box-icon name='chevron-right' style="width: 15px; height: 15px;"></box-icon></span> <router-link to="/que-es-un-registro-inicial">¿Qué es un Registro Inicial?</router-link>
                                    <ul>
                                        <li><router-link to="/como-hacerle-los-papeles-a-un-perro">¿CÓmo hacerle los papeles a un perro?</router-link></li>
                                        <li><router-link to="/por-que-lo-hacemos">¿Por qué hacemos registros iniciales?</router-link></li>
                                        <li><router-link to="/significado-pedigree">Significado de Pedigree</router-link></li>
                                        <li><router-link to="/como-tener-criadero-oficial">¿CÓmo tener un criadero oficial de perros?</router-link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><router-link to="/lista-de-precios">Lista de Precios</router-link></li>
                        <li>Trámites <span class="menu-icon"><img src="../assets/menu-icon.svg"></span>
                            <ul>
                                <li><router-link to="/formularios-pdf">Formularios PDF</router-link></li>
                                <li><router-link to="/inscripcion-criadero">Inscripción Criadero</router-link> </li>
                                <li><router-link to="/inscripcion-lechigada">Formulario Único / Inscripción de Camada</router-link></li>
                                <li><router-link to="/denuncia-de-servicio-dueno-del-macho">Denuncia de servicio dueño del macho</router-link></li>
                                <li><router-link to="/transferencias-online">Transferencia Online</router-link></li>
                                <li><router-link to="/formulario-registros-iniciales">Formulario para registros iniciales</router-link></li>
                                <li><router-link to="/denuncia-de-venta-de-canino">Denuncia de venta de canino</router-link></li>
                    
                            </ul>
                        </li>
                        <li>Razas <span class="menu-icon"><img src="../assets/menu-icon.svg"></span>
                            <ul>
                                <li><router-link to="/grupos-caninos">Grupos Caninos</router-link></li>
                                <li><router-link to="/patron-de-los-mantos-de-los-perros">PatrÓn de los Mantos de los Perros</router-link> </li>
                            </ul>
                        </li>
                        <li><router-link to="/reglamentos-kcd">Reglamentos</router-link></li>
                        <li><router-link to="/contacto">Contacto</router-link></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';

    export default {
        data() {
            return {
                currentMenu: null,
            };
        },
        mounted() {
            this.handleResize(); 
            $(window).on('resize', this.handleResize);
        },

        methods: {
            handleResize() {
                $('.menu ul li ul').hide();

                if ($(window).width() > 800) {
                    this.bindDesktopMenuEvents();
                } else {
                    this.bindMobileMenuEvents();
                }
            },

            bindDesktopMenuEvents() {
                $('.menu ul li').off('pointerdown');

                $('.menu ul li').on('mouseenter', function () {
                    $(this).children('ul').fadeIn(100);
                });
                $('.menu ul li').on('mouseleave', function () {
                    $(this).children('ul').fadeOut(100);
                });
            },

            bindMobileMenuEvents() {
                $('.menu ul li').off('mouseenter mouseleave pointerdown');
                $('.icon').off('pointerdown');
                
                $('.menu ul li').on('pointerdown', function (e) {
                    var $ul = $(this).children('ul');

                    if ($ul.is(':visible')) {
                        $ul.slideUp();
                    } else {
                        var $ulparent = $(this).parent();
                        $('.menu ul li ul').not($ulparent).not($ul).slideUp();
                        $ul.slideDown();
                    }
                    e.stopPropagation();
                });

                $('.icon').on('pointerdown', function () {
                    $('.container-header').toggleClass('menu-open');
                    $('.icon').toggleClass('-open');
                });
            },
        },
    }
</script>




<style scoped lang="scss">
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.container-header{
    position: absolute;
    width: 100%;
    top: 50px;
    height: 110px;
    display: flex;
    justify-content: flex-end;
    z-index: 6000;
}

// .container-menu{

// }

.icon{
    display: none;
}

.menu{
    position: relative;
    right: 0;
}

.menu ul{
    display: flex;
    gap: 5px;
}

.menu ul li,
.menu ul li a{
    list-style: none;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    padding: 15px 10px;
    border-top: 4px solid transparent;
    cursor: pointer;
    transition: border-top 300ms;
    position: relative;
}

.menu ul li a{
    padding: 0 !important;
    margin-right: 0 !important;
}

.menu > ul  > li:hover{
    border-top: 4px solid #fff;
}

.menu ul li span{
    margin-left: 4px;
}

/* Submenu */ 
.submenu-icon{
    position: absolute;
    right: 10px;
    top: 16px;
}

.menu ul li ul{
    position: absolute;
    flex-direction: column;
    width: 306px;
    top: 100%;
    left: 0;
    border-radius: 5px;
    background-color: #fff;
    border-bottom: 2px solid #5d5e5f;
    text-wrap: balance;
}

.menu ul li ul li, 
.menu ul li ul li a{
    text-align: left;
    color: black;
    font-size: 12px;   
    list-style: none;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    border-radius: 5px;
    position: relative;
}

.menu ul li ul li{
    padding-left: 15px;
    padding-right: 0;
    margin-right: 0;
}

.menu ul li ul li:hover{
    background: rgb(128, 128, 128, 0.4);
    border-bottom: 1px solid #fff;
}

.menu ul li ul li ul{
    position: absolute;
    left: 310px;
    top: 0;
}

@media (min-width: 1365px) and (max-width: 1500px){
    .container-header{
        top: 100px !important;
    }

    .menu ul{
        gap: 0;
    }

    .menu ul li{
        margin-right: 5px !important;
    }
}

@media screen and (min-width: 1281px){
    .container-header{
        top: 120px;
    }

    .menu ul li,
    .menu ul li a{
        font-size: 18px;
        margin-right: 20px;
    }

    /* Submenu */ 
    .menu ul li ul li, 
    .menu ul li ul li a{
        font-size: 14px; 
    }

    .menu ul li ul li{
        padding-right: 15px;
    }
}

@media screen and (max-width: 800px){
    .container-header{
        top: 0;
        transform: translateX(0);
        transition: all 300ms;
        position: fixed;
    }

    .icon{
        display: block;
        position: absolute;
        right: 10px;
        top: 5px;
        background-color: rgb(194, 155, 104);;
        border: none;
        border-radius: 6px;
        box-shadow: 1px 1px 4px #000;
        transition: right 1s ease;
    }

    .submenu-icon{
        fill: #fff;
        top: 9px;
        right: 0;
    }

    .-open{
        right: -40px;
        transition: right 1s ease;
    }

    .menu-open{
        transform: translateX(-300px);
        top: 0;
    }

    .menu{
        width: 250px;
        height: 100vh;
        position: fixed;
        top: 0;
        right: -300px;
        margin-top: 0;
        background-color: #243757;
    }

    .menu ul{
        flex-direction: column;
    }

    .menu ul li, .menu ul li a{
        font-size: 13px;
        padding: 10px 10px;
    }

    .menu ul li ul{
        width: 100%;
        top: 0;
        border-bottom: none;
        background-color: #243757;
        position: relative;
        padding: 2px;
    }

    .menu ul li:hover{
        border-top: 4px solid transparent;
    }

    .menu ul li ul li:hover{
        background-color: rgb(128, 128, 128, 0.4);
    }

    .menu ul li ul li, 
    .menu ul li ul li a{
        color: #fff;
        font-size: 11px;
    }

    .menu ul li ul li ul{
        background-color: transparent;
        width: 100%;
        position: relative;
        left: 0;
    }

    .menu ul li ul li ul li, 
    .menu ul li ul li ul li:hover{
        border: none;
        top: 12px;
    }

    .menu ul li ul li ul li:hover,
    .menu ul li ul li ul li a:hover{
        background-color: transparent;
        color: black;
    }
}

</style>