<template>
    <section class="regulations">
        <div class="regulations_container">
            <h2 class="regulations_content title">Reglamentos del Kennel Club Digital </h2>
            <div class="regulations_div">
                <ul class="regulations_list paragraph">
                    <li class="regulations_item"><router-link to="/normas-de-excelencia">Normas de Excelencia</router-link></li>
                    <li class="regulations_item"><router-link to="/reglamento-de-crianza-y-criaderos">Reglamento de Crianza y Criaderos</router-link></li>
                    <li class="regulations_item"><router-link to="/anexo-categorias-y-duracion-de-criaderos">Anexo I - Categorías y Duración de Criaderos</router-link></li>
                    <li class="regulations_item"><router-link to="/reglamento-de-jueces">Reglamento de Jueces</router-link></li>
                </ul>
                <!-- <img class="regulations_img" src="../assets/regulations-img.png"> -->
            </div>
        </div>
    </section>
</template>


<script setup>
import { titleChange } from '../titleChange';

titleChange.setTitle('/reglamentos-kcd', 'Reglamentos');
</script>


<style lang="scss" scoped>
.regulations{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
}
.regulations_container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
}
.regulations_div{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.title{
    text-align: center;
    color: #243757;
    font-size: 45px;
    padding-bottom: 0;
}
.paragraph{
    text-align: justify;
    color: grey;
    font-size: 25px;
    text-align: left;
    line-height: 1.5;
    word-spacing: 7px;
    list-style: circle;
}
.regulations_item{
    margin-bottom: 40px;
    opacity: 0; 
    animation: list-in 0.5s forwards;
}

.regulations_list li:nth-child(1) {
    animation-delay: 0s;
}

.regulations_list li:nth-child(2) {
    animation-delay: 0.5s; 
}

.regulations_list li:nth-child(3) {
    animation-delay: 1s;
}
.regulations_list li:nth-child(4) {
    animation-delay: 1.5s;
}


@keyframes list-in {
    to {
        opacity: 1;
    }
}

.regulations_item a{
    color: rgb(68, 67, 67);
    text-decoration: none;
}

.regulations_item a:visited{
    color: #243757;
}

@media (max-width: 1280px){
    .regulations{
        padding-top: 90px; 
    }
    .regulations_container{
        padding: 10px 100px 30px 100px;
    }
    .title{
        font-size: 35px;
    }
    .paragraph{
        font-size: 20px;
        word-spacing: 5px;
        text-align: justify;
    }
    .regulations_list{
        width: 70%;
        margin-top: 0;
    }
    .regulations_item{
        margin-bottom: 30px;
        margin-top: 20px;
    }
    .regulations_img{
        width: 500px;
        height: 300px;
        margin: 20px 0 20px 0;
    }
}

@media (max-width: 800px){
    .regulations{
        padding: 10px;
        padding-bottom: 50px; 
    }

    .regulations_container{
        padding: 10px 35px;
    }

    .title{
        font-size: 25px;
    }

    .paragraph{
        font-size: 15px;
        text-align: left;
    }

    ul{
        padding-left: 10px;
    }

    .regulations_div{
        background-image: url(../assets/regulations-img.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        grid-template-columns: none;
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center;
    }
    
    .regulations_list{
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        padding-left: 40px;
    }
}




</style>