// src/composables/useTabManager.js
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export function useChangeTab() {
  const activeTab = ref(1);
  const router = useRouter();
  const route = useRoute();

  const changeTab = (tab) => {
    if (tab === 1) {
      router.push('/');
    } else if (tab === 2) {
      router.push('/software');
    }
    activeTab.value = tab;
  };

  watch(
    () => route.path,
    (newPath) => {
      if (newPath === '/software') {
        activeTab.value = 2;
      } else {
        activeTab.value = 1;
      }
    },
    { immediate: true }
  );

  return {
    activeTab,
    changeTab,
  };
}
