<template>
   <footer class="footer">
        <section class="footer_container">
            <p>Florida 670 1° Of. 45, <br>C1005 Cdad. Autónoma de Buenos Aires</p>
            <div class="footer_img">
                <a href="/"><img class="footer_img_item" id="kcd" src="../assets/kcd-lateral.png" alt="Kennel Club Digital"></a>
                <a href="https://kennelclubargentino.org.ar/" target="_blank"><img class="footer_img_item" src="../assets/kca.png" alt="Kennel Club Argentino"></a>
                <a href="https://www.asociacioncanofila.es/" target="_blank"><img class="footer_img_item" id="ace" src="../assets/ace.png" alt="Asociacion Canofila Espanola"></a>
            </div>
            
        </section>

        <div class="footer_copy">
            <h4 class="footer_copyright">Copyright &copy; 2024 Kennel Club Digital</h4>
            <h4 class="footer_copyright">Hecho por Aitana Villalba y Nana Nodar</h4>
        </div>
    </footer>
</template>


<script setup>
import { ref } from 'vue';
const iframeSrc = ref('https://www.instagram.com/kennelclubdigital/embed/');
</script>


<style lang="scss" scoped>
a{
    cursor: pointer;
}
.footer {
    background-color: #243757;
    height: 100%;
    padding: 90px;
    padding-top: 20px;
    padding-bottom: 15px;
    overflow: hidden;
}
.footer_container {
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    border-bottom: 1px solid #fff;
    flex-wrap: wrap;
}
.footer_img{
    display: flex;
    align-items: center;
}
.footer_img_item{
    width: 150px;
    height: 150px;
    margin: 10px;
}
.footer_copy {
    font-size: 20px;
    text-align: center;
    color: #fff;
    margin-top: 20px;
}

.footer_copyright {
    font-weight: 300;
}

h4{
    margin-top: 10px;
    margin-bottom: 0;
}

p{
    color: #fff;
    flex: 1;
    text-align: left;
    align-self: flex-end;
    font-size: 20px;
}

@media (max-width: 1280px){
    .footer{
        padding: 20px 40px 10px 70px;
    }
    
    .footer_img_item{
        width: 130px;
        height: 130px;
        margin: 10px;
    }
    
    .footer_copyright{
        font-size: 17px;
    }

    p{
        font-size: 14px;
    }
}

@media (max-width: 1023px){
    
    .footer_iframe{
        flex: 1;
    }
    .footer_img{
        flex: 2;
        align-self: center;
        justify-content: center;
    }
}

@media (max-width: 899px){
    .footer{
        padding: 20px 40px;
    }
}

@media (max-width: 800px){
    .footer{
        padding: 0;
        padding-bottom: 94px;
    }
    .footer_container{
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .footer_iframe{
        display: none;
    }

    .footer_img{
        display: grid;
        grid-template-columns: auto auto auto;
        justify-items: center;
        align-items: center;
    }
}

@media (max-width: 500px){
    .footer_container p{
        order: 2;
        text-align: center;
        margin-top: 0;
    }
    .footer_img_item{
        width: 100px;
        height: 100px;
        order: 1;
    }

    .footer_copyright{
        font-size: 13px;
    }
}
</style>