<template>
    <div class="form_container">
        <form @submit.prevent="submitForm" method="post" action="/formulario" novalidate enctype="multipart/form-data">
                <label for="nombre">NOMBRE (REQUERIDO):</label>
                <input v-model="formData.nombre" type="text" id="nombre" name="nombre">
                <div v-if="errors.nombre && errors.nombre.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.nombre">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="apellido">APELLIDO (REQUERIDO):</label>
                <input v-model="formData.apellido" type="text" id="apellido" name="apellido"> 
                <div v-if="errors.apellido && errors.apellido.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.apellido">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="organizacion">ORGANIZACION:</label>
                <input v-model="formData.organizacion" type="text" id="organizacion" name="organizacion">
                    <br>
                <label for="telefono">TELEFONO (REQUERIDO):</label>
                <input v-model="formData.telefono" type="tel" id="telefono" name="telefono">
                <div v-if="errors.telefono && errors.telefono.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.telefono">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="email">EMAIL (REQUERIDO):</label>
                <input v-model="formData.email" type="email" id="email" name="email">
                <div v-if="errors.email && errors.email.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.email">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="ciudad">CIUDAD / LOCALIDAD (REQUERIDO):</label>
                <input v-model="formData.ciudad" type="text" id="ciudad" name="ciudad">
                <div v-if="errors.ciudad && errors.ciudad.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.ciudad">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="provincia">PROVINCIA (REQUERIDO):</label>
                <input v-model="formData.provincia" type="text" id="provincia" name="provincia">
                <div v-if="errors.provincia && errors.provincia.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.provincia">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="pais">PAIS (REQUERIDO):</label>
                <input v-model="formData.pais" type="text" id="pais" name="pais">
                <div v-if="errors.pais && errors.pais.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.pais">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="asunto">ASUNTO (REQUERIDO):</label>
                <input v-model="formData.asunto" type="text" id="asunto" name="asunto"> 
                <div v-if="errors.asunto && errors.asunto.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.asunto">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="mensaje">TU MENSAJE (REQUERIDO):</label>
                <textarea class="mensaje" v-model="formData.mensaje" type="text" id="mensaje" name="mensaje"></textarea>
                <div v-if="errors.mensaje && errors.mensaje.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.mensaje">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label class="archivo" for="archivo">Adjunta un archivo (.pdf, .doc, .docx, .png, .jpg, .jpeg):</label>
                <input ref="fileInput" type="file" id="archivo" name="archivo" @change="handleFileChange" multiple required>
                <div v-if="errors['files.0'] && errors['files.0'].length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors['files.0']" :key="error">{{ error }}</li>
                  </ul>
                </div>
                <br>

                <button class="submit" type="submit">Enviar</button>
                <span id="mensajeJS"></span>
                
        </form>
    </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{
      formData: {},
      archivo: [],
      errors: {},
      errorMessage: null,
    };
  },
  methods: {
    handleFileChange(event) {
      const selectedFiles = event.target.files;
      // Asegurarse de no permitir más de tres archivos
      if (selectedFiles.length > 3) {
        this.archivos = [];
        this.errors.archivo = ["Solo puedes adjuntar hasta 3 archivos."];
      } else {
        this.archivos = Array.from(selectedFiles); // Convertir a array para su manejo
        this.errors.archivo = null; // Borrar errores si no hay problemas
      }
    },
    resetForm() {
      this.formData = {
        nombre: '',
        apellido: '',
        organizacion: '',
        telefono: '',
        email: '',
        ciudad: '',
        provincia: '',
        pais: '',
        asunto: '',
        mensaje: ''
      };
      this.$refs.fileInput.value = '';
      this.archivos = [];
    },

    submitForm (){
      this.errors = {};
      grecaptcha.ready(() => {
        grecaptcha.execute('6Lfgx6ApAAAAALjaik-3RpTbo4eyKCF82nuu3Xgg', { action: 'submitContact' })
        .then(token => {
          let mensaje = document.getElementById('mensajeJS');
          mensaje.textContent = 'Enviando formulario...';
          mensaje.className = 'load';
          const formData = new FormData(); 
          formData.append('g-recaptcha-response', token);
          // Agregar otros campos al FormData
          for (let key in this.formData) {
            formData.append(key, this.formData[key]);
          }
          // Verifica si la lista de archivos no está vacía
          if (this.archivos && this.archivos.length > 0) {
            this.archivos.forEach((archivo) => {
              formData.append('files[]', archivo); // Usa 'files[]' para múltiples archivos
            });
          }
          // Envío del formulario
          axios.post('https://api.kennelclubdigital.com/api/formulario-contacto', formData,{
            headers: {
              'Content-Type': 'multipart/form-data', // Establece el tipo de contenido como multipart/form-data
            },
          })
          .then(response => {
            mensaje.textContent = 'Correo enviado.';
            mensaje.className = 'enviado';
            this.resetForm();
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 422) {
                // Errores de validación
                let mensaje = document.getElementById('mensajeJS');
                mensaje.textContent = 'Ocurrió un error al enviar el formulario. Revise los campos.';
                mensaje.className = 'error';
                // Asigna los mensajes de error a cada campo correspondiente
                this.errors = error.response.data.error;
              }else if(error.response.status === 403){
                mensaje.textContent = 'Fallo el recaptcha. Vuelva a intentarlo.';
                mensaje.className = 'error';
              }
            }
          })
        })
      })
    },
  }   
} 
</script>


<style lang="scss" scoped>
.form_container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_container form {
  width: 60%; 
  display: flex;
  flex-direction: column;
}

label{
    font-size: 17px;
    margin: 10px;
    margin-left: 0;
    font-weight: bold;
    color: rgb(70, 69, 69);
}
input{
    height: 4vh;
}

.archivo{
    margin-top: 20px;
}
.mensaje{
    height: 13vh;
}

.submit{
    cursor: pointer;
    align-self: center;
    margin: 20px;
    font-size: 1.3rem;
    font-weight: bold;
    background-color: #E19F41;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
}

.submit:hover{
    background-color: #915f1a;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.enviado{
  font-weight: bold;
  color: green;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.error{
  font-weight: bold;
  color: red;
  margin: 10px auto 30px auto;
  font-size: 20px;
}
.load{
  font-weight: bold;
  color: black;
  margin: 10px auto 30px auto;
  font-size: 20px;
}



@media (max-width: 800px){
  .form_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    justify-items: center; 
    align-items: center;
  }

  .form_container form{
    width: 80%;
  }
}

@media (max-width: 500px){
  label{
    font-size: 13px;
  }

  input{
    height: 3vh;
  }
}
</style>