<template>
  <div >
    <div class="new_buttons" >
      <button @click="changeTo(1)" class="buttons_new" :class="{ new_register_register: activeTab === 1 }">
        <p>Registro</p>
      </button>
      <router-link to="/software" @click.native="changeTo(2)" class="buttons_new" :class="{ new_software_register: activeTab === 1 }">
        <p>Software</p>
      </router-link>
    </div>
  </div>
</template>
    
<script setup>
  import { ref, inject } from 'vue';

  const changeTab = inject('changeTab');
  const activeTab = inject('activeTab', ref(1));

  const changeTo = (tab) => {
    if (changeTab) {
      changeTab(tab);
    } else {
      console.error('No se pudo encontrar la función changeTab');
    }
  };
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
  
  *{
    font-family: 'Lato', sans-serif;
  }

  // New Buttons Software Only 
  .new_buttons, .buttons_new, .new_register_register, .new_software_register{
    display: none;
  }
  

  @media (max-width: 800px){

    // New Buttons Software Only 
    a{
      text-decoration: none;
    }
    .new_buttons{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 64%;
      left: 0;
      right: 0;
      overflow: hidden;
      width: 100%;
      z-index: 4000;
    }

    .buttons_new{
      width: 50%;
      height: 6vh;
      font-size: 2rem;
      cursor: pointer;
      border: none;
      clip-path: none;
    }

    .new_register_register{
      display: block;
      background-color: rgb(194, 155, 104);
    }

    .new_software_register{
      display: block;
      background-color: #243757;
    }

    .new_register_register p,  .new_software_register p{
      font-size: 0.6em;
      color: #fff;
      text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.5);
      margin: 0;
    }

    .new_software_register p{
      height: 6vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 500px){
    .new_buttons{
      top: 69%;
    }
  }
 
</style>
