<template>
  <section class="lechigada_container">
    <div class="container">
      <h2 class="lechigada_content title">Formulario Único de Inscripción <br> de Camada / Lechigada</h2>
      <p class="lechigada_content subtitle">Formulario unificado para realizar Denuncia de Servicio, Nacimiento y Lechigada. <br>De esta forma, podrá obtener, en caso de aceptación, los papeles de cada uno de los perros nacidos y declarados.</p>
      <FormLechigada/>
    </div>
  </section>
</template>
  
  
<script setup>
  import FormLechigada from '../components/FormLechigada.vue';
  import { onMounted, onUnmounted } from 'vue';
  import { canonicalService } from '../canonicalService';
  import { titleChange } from '../titleChange';

  onMounted(() => {
    canonicalService.addLink('https://kennelclubargentino.org.ar/formulario-unico-via-e-mail/');
  });
  onUnmounted(() => {
    canonicalService.removeLink('https://kennelclubargentino.org.ar/formulario-unico-via-e-mail/');
  });

  titleChange.setTitle('/inscripcion-lechigada', 'Formulario Único de Inscripción de Camada - Lechigada');
  
</script>
  
  
<style lang="scss" scoped>
  .lechigada_container{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
  }
  
  .container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
    animation: zoomIn 2s ease;
  }
  
  @keyframes zoomIn {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }
  .title{
      text-align: center;
      color: #243757;
      font-size: 45px;
      padding-bottom: 0;
      margin-bottom: 15px;
    }
  
    .subtitle{
      text-align: center;
      color: grey;
      font-size: 22px;
      margin-bottom: 60px;
    }
    @media (max-width: 1400px){
    .lechigada_container{
        padding-top: 160px; 
    }
}

  @media (max-width: 1280px){
    .lechigada_container{
      padding-top: 90px;
    }
    .container{
      padding: 10px 100px 30px 100px;
    }
    .title{
      font-size: 35px;
    }
    .subtitle{
      font-size: 20px;
      margin-bottom: 40px;
      text-wrap: balance;
    }
  }

  @media (max-width: 800px){
    .lechigada_container{
      padding: 10px;
      padding-bottom: 20px;
    }

    .container{
      padding: 10px 35px;
      overflow: hidden;
    }

    .title{
      font-size: 25px;
      text-wrap: balance;
    }

    .subtitle{
      font-size: 18px;
      margin-bottom: 25px;
      text-wrap: balance;
    }
  }
</style>