<template>
    <section>
        <section class="why_register">
            <div class="why_register_container">
                <h2 class="why_register_content title">¿Por qué hacemos Registros Iniciales?</h2>
                <div class="why_register_content paragraph"> El Kennel Club Digital está bajo las normas del <a href="https://kennelclubargentino.org.ar/" target="_blank">Kennel Club Argentino</a>, el cual como Registro Oficial Canino de la República Argentina trabaja con estándares internacionales, respetando las normativas que rigen los registros.
                    <div class="img" style="display: flex; align-content: center; justify-content: center; margin: 50px;">
                        <img src="../assets/logo-kca-horizontal.webp" alt="Logo KCA">
                    </div>
                El Derecho Administrativo, en todo el mundo, estipula que hay bienes registrables, bienes muebles e inmuebles; dentro de los bienes muebles se encuentran los <b>animales de raza</b>, el Kennel Club Argentino, como Registro Oficial de Perros de Pura Raza, no puede limitar el derecho a registrar un bien, como es un perro de raza.
                    <br><br>
                Como Registro Oficial de la República Argentina, tiene normativas internas sobre cómo y qué registrar, teniendo jueces Oficiales que conocen los fenotipos de las diferentes razas, pudiendo ser consignado como «No Apto para Cria», pero sí como de raza pura.
                    <br>
                Tiene Jueces capacitados para poder realizar el trabajo con profesionalismo, resguardando el derecho que todos los ciudadanos tenemos frente a la ley.
                    <br><br>
                Si usted tiene alguna duda o consulta, <a href="/contacto">contáctenos</a>.
                </div>
            </div>
        </section>
    </section>
</template>


<script setup>
import { onMounted, onUnmounted } from 'vue';
import { canonicalService } from '../canonicalService';
import { titleChange } from '../titleChange';

onMounted(() => {
  canonicalService.addLink('https://kennelclubargentino.org.ar/por-que-lo-hacemos/');
});
onUnmounted(() => {
  canonicalService.removeLink('https://kennelclubargentino.org.ar/por-que-lo-hacemos/');
});

titleChange.setTitle('/por-que-lo-hacemos', '¿Por qué hacemos Registros Iniciales?')

</script>


<style lang="scss" scoped>
a{
    text-decoration: none;
}
.why_register{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
}
.why_register_container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
    animation: slideFromBottom 0.8s ease-in-out;
}

@keyframes slideFromBottom {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }

.title{
    text-align: center;
    color: #243757;
    font-size: 45px;
    padding-bottom: 0;
}
.paragraph{
    text-align: justify;
    color: grey;
    font-size: 22px;
    text-align: left;
    line-height: 1.5;
    word-spacing: 7px;
}
@media (max-width: 1400px){
    .why_register{
        padding-top: 160px; 
    }
}

@media (max-width: 1280px){
    .why_register{
        padding-top: 90px; 
    }
    .why_register_container{
        padding: 10px 100px 30px 100px;
    }
    .title{
        font-size: 35px;
    }
    .paragraph{
        font-size: 20px;
        word-spacing: 5px;
        text-align: justify;
    }
}

@media (max-width: 800px){
    .why_register{
        padding: 10px;
        padding-bottom: 20px;
    }
    .why_register_container{
        padding: 10px 35px;
        padding-bottom: 20px;
    }

    .title{
        font-size: 25px;
    }

    .paragraph{
        font-size: 14px;
    }

    .img{
        margin: 40px 0 !important; 
    }

    .img img{
        width: 230px;
        margin: 0;
    }

}
</style>