<template>
    <section class="standards">
        <div class="standards_container">
            <h2 class="standards_content title">Normas de Excelencia el Kennel Club Digital</h2>
            <div class="standards_content paragraph"><br>
                <div style="text-align: center; border-bottom: 1px solid grey; border-top: 1px solid grey;">
                <p>El<b>Kennel Club Digital está bajo Las Normas de Excelencia del Kennel Club Argentino</b>,<br> las cuales se basan en las mejores prácticas y estándares internacionales para garantizar <br>el bienestar de los perros y fomentar una crianza responsable. <br> Éstas son un conjunto de estándares que reflejan nuestro firme compromiso con el bienestar y la crianza <br> de nuestros queridos compañeros caninos,  y nuestra dedicación a la crianza ética y al bienestar animal. <br><i>Nuestro compromiso va más allá de los estándares de raza</i>.</p></div>
                <br>
                <h3>¿CUÁLES SON ESTAS NORMAS?</h3>
                <ol>
                    <li> <b>Libreta de Bienestar Animal</b><br> Desde el Kennel Club, se estimula al uso de la Libreta de Bienestar Animal y sus prácticas recomendadas.</li>
                    <li> <b>Registro de Pedigrees Transparente</b><br> Los registros de pedigrees deben ser precisos, sin omisiones, para que lo nuevos/as propietarios/as puedan acceder a una genealogía veraz y poder garantizar la trazabilidad de los ancestros.</li>
                    <li> <b>Registro Completo de Cachorros</b><br> Todos los cachorros nacidos de un criadero deberían ser debidamente registrados en el Kennel Club Digital. Es una buena práctica que la venta de cachorros sea siempre con su documentación oficial que respalde su origen y genealogía, garantizando así la trazabilidad y la autenticidad de la raza.</li>
                    <li> <b>Selección Responsable de Padres</b><br> Se debe promover la selección de padres sanos, que no presenten patologías visibles antes de la cría.</li>
                    <li> <b>Enfoque en el Bienestar Animal</b><br>El bienestar y la salud de los perros deben ser la principal preocupación, por encima de consideraciones estéticas.</li>
                    <li> <b>Condiciones de Vida Adecuadas</b><br>Los criadores deben proporcionar instalaciones limpias y seguras, adecuadas al tamaño y las necesidades de la raza.</li>
                    <li> <b>Estimulación y Socialización</b><br>Los cachorros deben ser criados en un entorno estimulante y socializado con personas y otros perros desde una edad temprana.</li>
                    <li> <b>Control de Temperamento</b><br>Los criadores/as deben dar prioridad al temperamento equilibrado en sus líneas de cría.</li>
                    <li> <b>Control de Población</b><br>Se debe evitar la promoción de cría indiscriminada para evitar la sobrepoblación y garantizar la atención adecuada a los cachorros. Cada cachorro debe poder acceder a un hogar responsable.</li>
                    <li> <b>Atención Veterinaria</b><br>Se requiere atención veterinaria regular para padres y cachorros, incluyendo vacunación, desparasitación y exámenes de salud si fuese necesario.</li>
                    <li> <b>Control de Enfermedades Hereditarias</b><br>Los/as criadores/as deben esforzarse en eliminar enfermedades genéticas hereditarias en la línea de cría.</li>
                    <li> <b> Educación del Propietario/as</b><br>Los/as compradores/as o destinatarios/as finales deben recibir información detallada sobre la raza, sus necesidades y responsabilidades como tutores/as.</li>
                    <li> <b>Priorizar la Salud sobre la Cantidad</b><br>Se enfatiza que la crianza responsable se basa en la salud de los cachorros, no en la cantidad. Los/as criadores/as deben evitar prácticas de cría masiva sin propósito ni control y en lugar de eso centrarse en mejorar la salud y el temperamento de la raza.</li>
                    <li> <b>Prohibición de Cruces Inhumanos</b><br>Se prohíben cruces que puedan causar sufrimiento innecesario o problemas de salud. El bienestar de los perros es nuestra prioridad.</li>
                    <li> <b>Prohibición de Amputaciones Estéticas</b><br>Reafirmamos nuestro compromiso con el respeto a la totalidad de nuestros queridos compañeros caninos, y por lo tanto, queda terminantemente prohibida cualquier amputación estética. Cada perro merece ser amado y apreciado en su forma natural y auténtica.</li>
                    <li> <b>Rehabilitación y Adopción</b><br>Si se opta por la adopción de perros de cría retirados. Se recomienda la castración antes de la adopción.</li>
                    <li> <b>Educación Continua</b><br>Los/as criadores/as deben comprometerse a la educación continua en cinología y bienestar animal.</li>
                    <li> <b>Descanso y Recreación</b><br>Los perros deben tener tiempo adecuado para descansar y jugar al aire libre.</li>
                    <li> <b>Prohibición de Castigos Físicos</b><br>Se prohíbe el uso de castigos físicos o métodos crueles de entrenamiento.</li>
                    <li> <b>Apoyo Voluntario a Investigaciones Científicas</b><br>Los/as criadores/as pueden optar por apoyar de manera voluntaria la investigación científica ética para mejorar la salud de la raza.</li>
                    <li> <b>Promover el microchip</b><br>Se recomienda que todos los perros criados estén microchipiados para garantizar la identificación y el seguimiento adecuados de cada individuo a lo largo de su vida. En caso de los cachorros se debe crear conciencia entre el público para que a la edad adecuada realicen la práctica.</li>
                    <li> <b>Uso Responsable de la Tecnología de Reproducción Asistida</b><br>Si se utilizan técnicas de reproducción asistida, como la inseminación artificial, deben ser realizadas por profesionalmente, con un enfoque en la salud y el bienestar de los perros.</li>
                    <li> <b>Fomentar la Esterilización</b><br>Los/as criadores/as deben fomentar la esterilización o castración de los perros y perras no destinados a la cría o a programas de mejora de la raza para evitar la reproducción no planificada y contribuir a controlar la población.</li>
                    <li> <b>Evaluación de la Compatibilidad entre Propietario/as y Raza</b><br>Los/as criadores/as deben evaluar la compatibilidad entre el propietario/as y la raza para garantizar que el perro se adapte al estilo de vida y las necesidades tanto del propietario/as como del perro y perras.</li>
                    <li> <b>Publicidad Veraz</b><br>La publicidad debe ser precisa y honesta, evitando exageraciones o promesas engañosas. Se recomienda siempre poner el nombre del criadero y su número de registro.</li>
                    <li> <b>Ética en la Comercialización</b><br>Los cachorros solo deben ser entregados a personas que estén preparadas para cuidar de ellos adecuadamente y se recomienda el uso del “CONVENIO DE CESIÓN DE CACHORRO” que el Kennel Club Digital pone a disposición de forma gratuita.</li>
                </ol>
                <br>
                <span style="font-size: 15px;">Las Normas de Excelencia poseen derechos de autor Legajo Nº: RL-2023-123863319-APN-DNDA#MJ</span>
            </div>
            
        </div>
    </section>
</template>


<script setup>
import { onMounted, onUnmounted } from 'vue';
import { canonicalService } from '../canonicalService';
import { titleChange } from '../titleChange';

onMounted(() => {
  canonicalService.addLink('https://kennelclubargentino.org.ar/normas-de-excelencia-del-kennel-club-argentino/');
});
onUnmounted(() => {
  canonicalService.removeLink('https://kennelclubargentino.org.ar/normas-de-excelencia-del-kennel-club-argentino/');
});

titleChange.setTitle('/normas-de-excelencia', 'Normas de Excelencia');

</script>

<style lang="scss" scoped>
.standards{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
}
.standards_container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
    animation: fadeIn 1s forwards; 
}

@keyframes fadeIn {
    from {
        opacity: 0; 
    }
    to {
        opacity: 1; 
    }
}
.title{
    text-align: center;
    color: #243757;
    font-size: 45px;
    padding-bottom: 0;
}
.paragraph{
    text-align: justify;
    color: grey;
    font-size: 22px;
    text-align: left;
    line-height: 1.5;
    word-spacing: 7px;
}

li{
    margin-bottom: 15px;
}

b{
    margin-left: 5px;
}

@media (max-width: 1280px){
    .standards{
        padding-top: 90px; 
    }
    .standards_container{
        padding: 10px 100px 30px 100px;
    }
    .title{
        font-size: 35px;
    }
    .paragraph{
        font-size: 20px;
        word-spacing: 5px;
        text-align: justify;
    }
  }

  @media (max-width: 800px){
    .standards{
        padding: 10px;
        padding-bottom: 20px;
    }

    .standards_container{
        padding: 10px 35px;
        padding-bottom: 20px;
    }

    .title{
        font-size: 25px;
    }

    .paragraph{
        font-size: 14px;
    }

    ol{
        padding-left: 20px;
    }

    span{
        font-size: 12px !important;
        text-align: left !important;
    }
}
</style>